import Moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { capitalize, groupBy } from 'lodash';
import { faCalendarPlus } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive';
import { useMemo, useState } from 'react';
import QueryString from 'qs';

import AssigneeHoverInput from './AssigneeHoverInput';
import ArchiveButton from '../../../library/inputs/ArchiveButton';
import Button from '../../../library/inputs/Button';
import CancelAvailabilityButton from '../CancelAvailabilityButton';
import CancelMeetingButton from '../CancelMeetingButton';
import CandidateActionButton from '../CandidateActionButton';
import CheckboxInput from 'components/library/inputs/CheckboxInput';
import FavoriteButton from '../../../library/inputs/FavoriteButton';
import Flash from '../../../library/utils/Flash';
import Label from '../../../library/utils/Label';
import NewFeatureLabel from '../../../library/utils/NewFeatureLabel';
import SchedulingStatusIndicator from '../../../library/utils/SchedulingStatusIndicator';
import StatusIndicator from '../../../library/utils/StatusIndicator';
import Table from '../../../library/data-display/Table';
import Tooltip from '../../../library/utils/Tooltip';
import UseHeldScheduleModal from '../../../library/data-display/UseHeldScheduleModal';
import pluralize from '../../../../libraries/pluralize';
import { atsLabels, ApplicationSchedulingStatus, FavoriteResourceType } from '../../../../types';
import { formatMoment, TimeFormat } from '../../../../libraries/time';
import { formatList } from 'libraries/formatters';
import { useLDFlags } from '../../../../hooks/use-ld-flags';
import { useSession } from '../../../../hooks/use-session';
import { useUpdateApplication } from '../../../../hooks/queries/applications';
import { checkApplicationsForDifferentStages } from './helpers';

import type { Application } from '../../../../types';
import type { ChangeEvent, ReactNode } from 'react';
import type { TableSchema } from '../../../library/data-display/Table';
import { correctPath } from 'libraries/gem';

const getRowLink = ({ id }: Application) => correctPath(`/app/candidates/${id}`);

const BULK_SELF_SCHEDULE_CHECKBOX_INPUT_NAME_PREFIX = 'bulk-self-schedule-checkbox-input-application-';
// Keep the max selection size in sync with the max size allowed on the server
// for the application_ids param on the self-scheduling creation endpoint
// TODO @benhiller: Increase to 50 once timeouts are fixed
const MAX_SELECTION_SIZE = 35;

interface Props {
  applications: Application[];
  onPageNumberChange: (pageNumber: number) => void;
  onShowArchivedChange: (event: ChangeEvent<HTMLInputElement>) => void;
  pageNumber: number;
  pageSize: number;
  showArchived: boolean;
  totalCount: number;
}

const CandidateListTable = ({
  applications,
  onPageNumberChange,
  onShowArchivedChange,
  pageNumber,
  pageSize,
  showArchived,
  totalCount,
}: Props) => {
  const { applicationAssignees, bulkSelfScheduling } = useLDFlags();
  const { account } = useSession();

  // Map from application ID => Application
  const [selectedApplications, setSelectedApplications] = useState<Map<string, Application>>(new Map());

  const appsHaveDifferentStages = checkApplicationsForDifferentStages(selectedApplications);

  const isSmallerScreen = useMediaQuery({ query: '(max-width: 1250px)' });
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1500px)' });

  const [actionButtonSuccessFlashMessage, setActionButtonSuccessFlashMessage] = useState<ReactNode>(null);
  const [actionButtonErrorFlashMessage, setActionButtonErrorFlashMessage] = useState<ReactNode>(null);
  const [heldScheduleModalIsOpen, setHeldScheduleModalIsOpen] = useState<{ [key: string]: boolean }>({});
  const [lastAction, setLastAction] = useState({
    id: '',
    isUndo: false,
    resource: '',
    type: '',
  });

  const updateApplicationMutation = useUpdateApplication();

  const handleArchive = async (id: string, undo: boolean) => {
    setLastAction({
      id,
      type: 'archive',
      resource: 'candidate',
      isUndo: undo,
    });
    updateApplicationMutation.reset();
    updateApplicationMutation.mutate({ id, payload: { archived: true } });
  };

  const handleUnarchive = async (id: string, undo: boolean) => {
    setLastAction({
      id,
      type: 'unarchive',
      resource: 'candidate',
      isUndo: undo,
    });
    updateApplicationMutation.reset();
    updateApplicationMutation.mutate({ id, payload: { archived: false } });
  };

  const handleUndo = () => {
    if (lastAction.type === 'archive') {
      handleUnarchive(lastAction.id, true);
    }
    if (lastAction.type === 'unarchive') {
      handleArchive(lastAction.id, true);
    }
  };

  const handleCancelDismiss = () => {
    setLastAction({
      id: '',
      isUndo: false,
      resource: '',
      type: '',
    });
  };

  const handleCancelSuccess = (id: string, resource: string) => {
    setLastAction({
      id,
      type: 'cancel',
      resource,
      isUndo: false,
    });
  };

  const handleHeldScheduleOpen = (id: string) => {
    setHeldScheduleModalIsOpen((prev) => ({
      ...prev,
      [id]: true,
    }));
  };

  const toggleHeldScheduleOpen = (id: string) => {
    setHeldScheduleModalIsOpen((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const canSelectApplication = (app: Application): boolean => {
    return app.scheduling_status !== ApplicationSchedulingStatus.Unschedulable && app.scheduling_status !== ApplicationSchedulingStatus.Inactive;
  };

  const handleBulkScheduleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const changedAppId = e.target.name.replace(BULK_SELF_SCHEDULE_CHECKBOX_INPUT_NAME_PREFIX, '');
    const changedApp = applications.find((app) => app.id === changedAppId);
    if (!changedApp) {
      // This shouldn't happen, since people are selecting from the currently
      // displayed applications.
      return;
    }

    const newSelectedApps = new Map(selectedApplications);
    if (e.target.checked) {
      newSelectedApps.set(changedAppId, changedApp);
    } else {
      newSelectedApps.delete(changedAppId);
    }
    setSelectedApplications(newSelectedApps);
  };

  const handleHeaderBulkScheduleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newSelectedApps = new Map(selectedApplications);
    applications.forEach((app) => {
      if (e.target.checked && canSelectApplication(app)) {
        newSelectedApps.set(app.id, app);
      } else {
        newSelectedApps.delete(app.id);
      }
    });
    setSelectedApplications(newSelectedApps);
  };

  const areAllAppsSelected = applications.every((app) => !canSelectApplication(app) || selectedApplications.has(app.id));
  const canAppsBeSelected = applications.some((app) => canSelectApplication(app));

  const columns = useMemo<TableSchema<Application>>(() => {
    return [{
      header: '',
      displayValue: ({ favorite, id }) => (
        <FavoriteButton favorite={favorite} resourceId={id} resourceType={FavoriteResourceType.Application} />
      ),
      isClickable: true,
    }, (bulkSelfScheduling && {
      header:
        <div className="candidate-list-table-row-checkbox-container" onClick={(e) => e.stopPropagation()}>
          <CheckboxInput
            isChecked={canAppsBeSelected && areAllAppsSelected}
            isDisabled={!canAppsBeSelected}
            name={`${BULK_SELF_SCHEDULE_CHECKBOX_INPUT_NAME_PREFIX}ALL`}
            onChange={handleHeaderBulkScheduleChange}
          />
        </div>,
      isClickable: true,
      displayEditValue: (app) => (
        <div className="candidate-list-table-row-checkbox-container" onClick={(e) => e.stopPropagation()}>
          <CheckboxInput
            isChecked={selectedApplications.has(app.id)}
            // Disable for candidates who cannot be sent a self-scheduling link
            // This might make sense to change if we use app selection for more
            // actions in the future.
            isDisabled={!canSelectApplication(app)}
            name={`${BULK_SELF_SCHEDULE_CHECKBOX_INPUT_NAME_PREFIX}${app.id}`}
            onChange={handleBulkScheduleChange}
          />
        </div>
      ),
    }), {
      header: 'Name',
      displayValue: ({ id, archived, candidate }) => (
        <div className="candidate-list-table-name">
          {candidate.name || <span className="unset" data-for={`${id}-name-not-set-tooltip`} data-tip>Unknown</span>}
          {!candidate.name && (
            <Tooltip id={`${id}-name-not-set-tooltip`} value={`This candidate's name isn't set in ${atsLabels[account?.ats_type!]}. We recommend setting it for the best experience.`} />
          )}
          {(archived || candidate.archived) && <Label color="gray">Archived</Label>}
        </div>
      ),
      hasLinkStyleOnHover: true,
    }, {
      header: 'Status',
      displayValue: ({ active_schedules, candidate, current_stage_id, held_schedules, id, scheduling_status }) => {
        const heldSchedulesByBlockId = groupBy(held_schedules || [], 'block_id');
        let numberOfHeldSchedules = 0;
        // Single-block held schedules
        numberOfHeldSchedules += heldSchedulesByBlockId['null']?.length || 0;
        // Multi-block held schedules
        numberOfHeldSchedules += Object.keys(heldSchedulesByBlockId).filter((blockId) => blockId !== 'null').length;
        return (
          <div className="candidate-list-table-status">
            <div className="candidate-list-table-status-indicator">
              <SchedulingStatusIndicator schedulingStatus={scheduling_status} />
              {active_schedules && active_schedules.length > 0 && (
                <span className="candidate-list-table-interview-date">
                  <span className="separator">|</span>
                  {(active_schedules.some((schedule) => schedule.stage_id === current_stage_id) ?
                    /*
                    `${formatList(active_schedules.map(({ interviews }) => formatMoment(Moment(interviews[0].start_time), TimeFormat.LongMonthDay)))}` :
                    */
                    active_schedules.map(({ interviews }) => formatMoment(Moment(interviews[0].start_time), TimeFormat.LongMonthDay)).join(', ') :
                    <StatusIndicator
                      color="orange"
                      tooltipId={id}
                      tooltipText={active_schedules.length === 1 ? `This upcoming schedule is for ${active_schedules[0].stage.name}` : active_schedules.every((schedule) => schedule.stage_id === active_schedules[0].stage_id) ? `These upcoming schedules are for ${active_schedules[0].stage.name}` : 'These upcoming schedules are for different stages'}
                    >
                      {formatList(active_schedules.map(({ interviews }) => formatMoment(Moment(interviews[0].start_time), TimeFormat.LongMonthDay)), ',')}
                    </StatusIndicator>
                  )}
                </span>
              )}
              {numberOfHeldSchedules > 0 &&
                <div className="candidate-list-table-held-schedules">
                  {numberOfHeldSchedules} {pluralize('schedule', numberOfHeldSchedules)} on hold
                </div>
              }
            </div>
            {Moment(candidate.lever_snoozed_until).isAfter(Moment()) && (
              <Label
                color="gray"
                tooltip={
                  <Tooltip
                    id={`${id}-snoozed-tooltip`}
                    value={`Snoozed until ${formatMoment(Moment(candidate.lever_snoozed_until).tz(Moment.tz.guess()), TimeFormat.ShortMonthDayYearWithTimeAndTimezone)}`}
                  />
                }
              >
                Snoozed
              </Label>
            )}
          </div>
        );
      },
    }, {
      header: 'Job',
      displayValue: ({ job }) => job.name,
    }, {
      header: 'Stage',
      displayValue: ({ current_stage }) => current_stage ? current_stage.name : <span className="unset">N/A</span>,
    }, applicationAssignees && {
      header: <span className="candidate-list-table-assignee-header">Assignee <NewFeatureLabel /></span>,
      displayValue: ({ active_assignments, id }) => {
        return (
          <AssigneeHoverInput
            applicationId={id}
            assignment={active_assignments?.[0]}
          />
        );
      },
      isClickable: true,
    }, !isSmallScreen && !applicationAssignees && {
      header: 'Date Applied',
      displayValue: ({ ats_created_at }) => <span className="date">{formatMoment(Moment(ats_created_at), TimeFormat.LongMonthDayYear)}</span>,
    }, {
      header: 'Actions',
      isClickable: true,
      displayValue: (application) => {
        const { active_schedules, archived, candidate, current_stage, held_schedules, id, scheduling_status } = application;
        const hasActiveSchedules = (active_schedules || held_schedules) && Object.keys((active_schedules || {})).length + (held_schedules || []).length > 0;

        return (
          <div className="action-buttons-container">
            <CandidateActionButton
              application={application}
              setErrorFlashMessage={setActionButtonErrorFlashMessage}
              setSuccessFlashMessage={setActionButtonSuccessFlashMessage}
              size="small"
            />
            {(scheduling_status === 'scheduled' || scheduling_status === 'scheduling_link_sent') && (
              held_schedules && held_schedules.length > 0 ?
                <>
                  <Button
                    className="btn-add"
                    color="gem-blue"
                    onClick={() => handleHeldScheduleOpen(id)}
                    size="small"
                    tooltip={
                      <Tooltip
                        id={`${id}-add-schedule-button`}
                        position="top"
                        value={`Schedule${scheduling_status === 'scheduled' ? ' additional interviews' : ''}`}
                      />
                    }
                    value={<FontAwesomeIcon icon={faCalendarPlus} />}
                  />
                  <UseHeldScheduleModal
                    application={application}
                    isOpen={heldScheduleModalIsOpen[id] || false}
                    onToggle={() => toggleHeldScheduleOpen(id)}
                  />
                </> :
                <Link to={correctPath(`/app/candidates/${id}/${current_stage?.schedule_template?.default_workflow === 'self_schedule' ? 'self-schedule' : 'schedule'}`)}>
                  <Button
                    className="btn-add"
                    color="gem-blue"
                    size="small"
                    tooltip={
                      <Tooltip
                        id={`${id}-add-schedule-button`}
                        position="top"
                        value={current_stage?.schedule_template?.default_workflow === 'self_schedule' ? 'Send another self-scheduling link' :
                          'Schedule additional interviews'}
                      />
                    }
                    value={<FontAwesomeIcon icon={faCalendarPlus} />}
                  />
                </Link>
            )}
            {(archived || (!hasActiveSchedules && scheduling_status !== 'availability_requested' && scheduling_status !== 'scheduling_link_sent')) && !candidate.archived &&
              <ArchiveButton
                id={`${id}-archive-button`}
                isArchived={archived}
                onArchive={() => handleArchive(id, false)}
                onUnarchive={() => handleUnarchive(id, false)}
              />
            }
            {scheduling_status === 'availability_requested' &&
              <CancelAvailabilityButton
                application={application}
                onSuccess={() => handleCancelSuccess(id, 'availability request')}
              />
            }
            {hasActiveSchedules &&
              <CancelMeetingButton
                application={application}
                onSuccess={() => handleCancelSuccess(id, 'schedule')}
              />
            }
          </div>
        );
      },
    }];
  }, [isSmallerScreen, isSmallScreen, heldScheduleModalIsOpen, handleBulkScheduleChange, selectedApplications]);

  return (
    <div className="candidate-list-table-container">
      <Flash
        isDismissible
        message={<>{capitalize(lastAction.resource)} cancelled.</>}
        onDismiss={handleCancelDismiss}
        showFlash={lastAction.id ? lastAction.type === 'cancel' : false}
        type="success"
      />
      <Flash
        isDismissible
        message={<>Candidate {lastAction.type}d. {!lastAction.isUndo && <a href="#" onClick={handleUndo}>Undo?</a>}</>}
        onDismiss={updateApplicationMutation.reset}
        showFlash={lastAction.type !== 'cancel' && updateApplicationMutation.isSuccess}
        type="success"
      />
      <Flash
        isDismissible
        message={actionButtonSuccessFlashMessage}
        onDismiss={() => setActionButtonSuccessFlashMessage('')}
        showFlash={Boolean(actionButtonSuccessFlashMessage)}
        type="success"
      />
      <Flash
        isDismissible
        message={actionButtonErrorFlashMessage}
        onDismiss={() => setActionButtonErrorFlashMessage('')}
        showFlash={Boolean(actionButtonErrorFlashMessage)}
        type="danger"
      />
      <Flash
        isDismissible
        message={updateApplicationMutation.error?.message}
        onDismiss={updateApplicationMutation.reset}
        showFlash={updateApplicationMutation.isError}
        type="danger"
      />
      <Table
        data={applications}
        getRowLink={getRowLink}
        isEditing={bulkSelfScheduling}
        isPaginated
        layout="vertical"
        onPageNumberChange={onPageNumberChange}
        onShowArchivedChange={onShowArchivedChange}
        pageNumber={pageNumber}
        pageSize={pageSize}
        prefixedAdditionalHeaderElements={
          bulkSelfScheduling && selectedApplications.size > 0 ?
            <div className="candidate-list-table-bulk-schedule-button-container">
              {selectedApplications.size} {pluralize('candidate', selectedApplications.size)} selected
              <Link to={{
                pathname: correctPath('/app/candidates/bulk-self-schedule'),
                search: QueryString.stringify(
                  { application: Array.from(selectedApplications.keys()) },
                  { addQueryPrefix: true, arrayFormat: 'repeat' },
                ),
              }}>
                <Button
                  className="btn-add"
                  color="gem-blue"
                  isDisabled={appsHaveDifferentStages || selectedApplications.size === 1 || selectedApplications.size > MAX_SELECTION_SIZE}
                  size="small"
                  tooltip={
                    (appsHaveDifferentStages || selectedApplications.size > MAX_SELECTION_SIZE) ? <Tooltip
                      id="bulk-self-scheduling-disabled-tooltip"
                      value={appsHaveDifferentStages ? 'You cannot bulk self-schedule candidates in different stages' : `You cannot bulk self-schedule more than ${MAX_SELECTION_SIZE} candidates`}
                    /> : undefined
                  }
                  value="Bulk self-schedule"
                />
              </Link>
            </div> : null
        }
        schema={columns}
        showArchived={showArchived}
        totalCount={totalCount}
      />
    </div>
  );
};

export default CandidateListTable;
