import { Breadcrumb } from 'react-breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet-async';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { keyBy } from 'lodash';
import Moment from 'moment';
import { useEffect, useMemo } from 'react';

import { constructAvailabilityTokens } from 'components/Application/Candidates/Candidate/CandidateRequestAvailability/helpers';
import AllowedTimesSummary from 'components/library/data-display/AllowedTimesSummary';
import CalendarEventTemplateSummary from 'components/library/data-display/CalendarEventTemplateSummary';
import EmailPreview from 'components/library/data-display/EmailPreview';
import EmailTemplateSummary from 'components/library/data-display/EmailTemplateSummary';
import Table from 'components/library/data-display/Table';
import MultiStepFormStep from 'components/library/inputs/MultiStepFormStep';
import Flash from 'components/library/utils/Flash';
import LoadingSpinner from 'components/library/utils/LoadingSpinner';
import { useAccount } from 'hooks/queries/accounts';
import { useCalendars } from 'hooks/queries/calendars';
import { useFeedbackForms } from 'hooks/queries/feedback-forms';
import { useInterviewBatch } from 'hooks/queries/interview-batches';
import { useCreateSelfSchedulingLink, useBulkCreateSelfSchedulingLinks } from 'hooks/queries/self-scheduling-links';
import { useRender, useTokens } from 'hooks/queries/tokens';
import { useUsersMap } from 'hooks/queries/users';
import { useLDFlags } from 'hooks/use-ld-flags';
import { useSession } from 'hooks/use-session';
import { resolveHiringRole } from 'libraries/email';
import { formatDuration } from 'libraries/formatters';
import pluralize from 'libraries/pluralize';
import { encodeObjectForPreview } from 'libraries/query-string';
import { Directory, EmailTemplateType, liveCodingLabels } from 'types';
import { constructSchedulePreview, constructScheduleTokensPayload, constructSelfSchedulingLinkPayload, constructSelfSchedulingTokensPayload, useSelfSchedulingApplications, pathForSelfScheduling } from './helpers';
import { Step, useNewSelfSchedulingLink } from './use-new-self-scheduling-link';

import InterviewerTemplatesSummary from 'components/library/data-display/InterviewerTemplatesSummary';
import type { TableSchema } from 'components/library/data-display/Table';
import { correctPath } from 'libraries/gem';
import type { SelfSchedulingLink } from './use-new-self-scheduling-link';

const pendingPreviewMessage = 'This token will be filled in when the candidate selects a time for the interview.';

const ReviewStep = () => {
  const history = useHistory();

  const data = useSelfSchedulingApplications();
  const { isBulk, applicationIDs } = data;
  const arbitraryApplication = data.arbitraryApplication!;
  const stage = arbitraryApplication.current_stage;

  const { candidateFacingInterviewDetails } = useLDFlags();

  const { currentUser } = useSession();
  const { data: account } = useAccount();
  const { data: calendars } = useCalendars();
  const { data: feedbackForms } = useFeedbackForms();
  const users = useUsersMap({ archived: true });

  const {
    completedStep,
    selfSchedulingLink: selfSchedulingLinkState,
    setCompletedStep,
    setSelfSchedulingLinkId,
    setBulkSelfSchedulingIds,
    setBulkSelfSchedulingErrors,
  } = useNewSelfSchedulingLink();

  // Throughout the Review step, we use a modified version of the self-scheduling link that converts the allowedTimes
  // to the working hours timezone.
  // Within the self-scheduling workflow, the allowedTimes are set to dates based on the system/local timezone to be compatible
  // with the date picker components.
  // But for previewing and saving the link, we want to strip the local timezone and replace it with the working hours timezone.
  // For example, say I'm (in PST) creating a link where the interviewer is in Europe and the allowed dates are 10/3/2024 - 10/5/2024.
  // We want the self-scheduling link to restrict the dates based on European time, not local time.
  const selfSchedulingLink = useMemo(() => {
    const timezone = selfSchedulingLinkState.schedule_template.business_hours[0].timezone || Moment.tz.guess();
    const allowedTimes = (selfSchedulingLinkState.schedule_template.allowed_times || [])
    .filter(({ start_time, end_time }) => Boolean(start_time) && Boolean(end_time))
    .map(({ start_time, end_time }) => ({
      start_time: Moment(start_time).clone().tz(timezone).startOf('day').toISOString(),
      end_time: Moment(end_time).clone().tz(timezone).endOf('day').toISOString(),
    }));
    return {
      ...selfSchedulingLinkState,
      schedule_template: {
        ...selfSchedulingLinkState.schedule_template,
        allowed_times: allowedTimes,
      },
    };
  }, [selfSchedulingLinkState]);

  const createSelfSchedulingLinkMutation = useCreateSelfSchedulingLink();
  const bulkCreateSelfSchedulingLinkMutation = useBulkCreateSelfSchedulingLinks();

  const stageInterviewsById = useMemo(() => keyBy(stage!.stage_interviews, 'id'), [stage!.stage_interviews]);
  const feedbackFormsById = useMemo(() => keyBy(feedbackForms?.feedback_forms, 'id'), [feedbackForms]);

  const {
    data: renderedAvailabilityMessage,
    error: availabilityMessageError,
  } = useRender({
    type: 'availability_message',
    plain_text: false,
    availability: constructAvailabilityTokens(arbitraryApplication, users),
    text: account?.availability_message,
  }, {
    enabled: Boolean(completedStep >= Step.Review && account?.availability_message),
  });

  const {
    data: renderedEmailSubject,
    error: emailSubjectError,
  } = useRender({
    type: 'self_scheduling_request_email',
    plain_text: true,
    self_scheduling_link: constructSelfSchedulingTokensPayload(arbitraryApplication, stageInterviewsById[selfSchedulingLink.stage_interview.id]?.name, selfSchedulingLink.stage_interview.interview_template.duration_minutes, selfSchedulingLink.stage_interview.interview_template.candidate_facing_name, selfSchedulingLink.stage_interview.interview_template.candidate_facing_details, users),
    text: selfSchedulingLink.schedule_template.self_scheduling_request_email_template?.subject,
  }, {
    enabled: Boolean(completedStep >= Step.Review && selfSchedulingLink.schedule_template.self_scheduling_request_email_template),
  });

  const {
    data: renderedEmailBody,
    error: emailBodyError,
  } = useRender({
    type: 'self_scheduling_request_email',
    plain_text: false,
    self_scheduling_link: constructSelfSchedulingTokensPayload(arbitraryApplication, stageInterviewsById[selfSchedulingLink.stage_interview.id].name, selfSchedulingLink.stage_interview.interview_template.duration_minutes, selfSchedulingLink.stage_interview.interview_template.candidate_facing_name, selfSchedulingLink.stage_interview.interview_template.candidate_facing_details, users),
    text: selfSchedulingLink.schedule_template.self_scheduling_request_email_template?.body,
  }, {
    enabled: Boolean(completedStep >= Step.Review && selfSchedulingLink.schedule_template.self_scheduling_request_email_template),
  });

  const { data: calendarTokens } = useTokens({
    type: 'candidate_calendar_event',
    schedule: constructScheduleTokensPayload(arbitraryApplication, users, selfSchedulingLink),
  }, { enabled: completedStep >= Step.Review });

  const { data: confirmationEmailTokens } = useTokens({
    type: 'confirmation_email',
    schedule: constructScheduleTokensPayload(arbitraryApplication, users, selfSchedulingLink),
  }, { enabled: Boolean(completedStep >= Step.Review && selfSchedulingLink.schedule_template.confirmation_email_template) });

  const { data: interviewBatch } = useInterviewBatch(selfSchedulingLink.interview_batch_id, { enabled: Boolean(selfSchedulingLink.interview_batch_id) });

  useEffect(() => {
    if (createSelfSchedulingLinkMutation.error) {
      document.querySelector('.content-container')?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [createSelfSchedulingLinkMutation.error]);
  useEffect(() => {
    if (bulkCreateSelfSchedulingLinkMutation.error) {
      document.querySelector('.content-container')?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [bulkCreateSelfSchedulingLinkMutation.error]);

  const handleNext = async () => {
    if (isBulk) {
      bulkCreateSelfSchedulingLinkMutation.reset();
    } else {
      createSelfSchedulingLinkMutation.reset();
    }

    const payload = constructSelfSchedulingLinkPayload(selfSchedulingLink);

    try {
      if (isBulk) {
        const data = await bulkCreateSelfSchedulingLinkMutation.mutateAsync({ payload });
        setBulkSelfSchedulingIds(data.self_scheduling_link_ids);
        setBulkSelfSchedulingErrors(data.errors);
        setCompletedStep(Step.BulkResults);
        history.push(pathForSelfScheduling(isBulk, applicationIDs, 'results'));
      } else {
        const data = await createSelfSchedulingLinkMutation.mutateAsync({ payload });
        if (selfSchedulingLink.schedule_template.self_scheduling_request_email_template) {
          history.push(correctPath(`/app/candidates/${arbitraryApplication.id}/schedules/${data.id}`));
        } else {
          setSelfSchedulingLinkId(data.id);
          setCompletedStep(Step.Review + 1);
          history.push(correctPath(`/app/candidates/${arbitraryApplication.id}/self-schedule/send`));
        }
      }
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  /* eslint-disable react/display-name */
  const schema = useMemo<TableSchema<SelfSchedulingLink>>(() => [{
    header: 'Interview',
    displayValue: ({ stage_interview }) => stageInterviewsById[stage_interview.id]?.name,
  }, Boolean(selfSchedulingLink.interview_batch_id) && {
    header: 'Superday',
    displayValue: () => interviewBatch?.name || <LoadingSpinner />,
  }, !selfSchedulingLink.interview_batch_id && {
    header: 'Duration',
    displayValue: ({ stage_interview }) => formatDuration(stage_interview.interview_template.duration_minutes),
  }, !selfSchedulingLink.interview_batch_id && {
    header: 'Interviewer Panel',
    displayValue: ({ stage_interview }) => {
      const interviewerTemplates = stage_interview.interview_template.interviewer_templates;
      if (!interviewerTemplates || interviewerTemplates.length === 0) {
        return (<i>No interviewers</i>);
      }
      return <InterviewerTemplatesSummary interviewerTemplates={interviewerTemplates} />;
    },
  }, {
    header: 'Candidate-Facing Name',
    displayValue: ({ stage_interview }) => stage_interview.interview_template.candidate_facing_name || stageInterviewsById?.[stage_interview.id].name,
  }, candidateFacingInterviewDetails && {
    header: 'Candidate-Facing Details',
    displayValue: ({ stage_interview }) => (
      stage_interview.interview_template.candidate_facing_details ?
        <div dangerouslySetInnerHTML={{ __html: stage_interview.interview_template.candidate_facing_details }} /> :
        <span className="empty">Not set</span>
    ),
  }, account?.ats_type === 'lever' && {
    header: 'Feedback Form',
    displayValue: ({ stage_interview }) => stage_interview.feedback_form_id ? feedbackFormsById[stage_interview.feedback_form_id]?.name : <div className="no-template">No feedback form selected</div>,
  }, {
    header: 'Allowed Dates',
    displayValue: ({ schedule_template }) => <AllowedTimesSummary allowedTimes={schedule_template.allowed_times} timezone={schedule_template.business_hours[0].timezone!} />,
  }, Boolean(selfSchedulingLink.schedule_template.self_scheduling_advanced_notice_hours) && {
    header: 'Advanced Notice',
    displayValue: ({ schedule_template }) => formatDuration(schedule_template.self_scheduling_advanced_notice_hours * 60),
  }, {
    header: 'Scheduling Options Interval',
    displayValue: ({ schedule_template }) => formatDuration(schedule_template.scheduling_interval_minutes),
  }, {
    header: 'Rescheduling',
    displayValue: ({ schedule_template }) => (
      schedule_template.self_scheduling_reschedule_enabled ?
        `Allowed${schedule_template.self_scheduling_reschedule_notice_hours ? ` unless it's ${formatDuration(schedule_template.self_scheduling_reschedule_notice_hours * 60)} before scheduled time` : ''}` :
        'Not allowed'
    ),
  }, {
    header: 'Cancellations',
    displayValue: ({ schedule_template }) => (
      schedule_template.self_scheduling_cancel_enabled ?
        `Allowed${schedule_template.self_scheduling_cancel_notice_hours ? ` unless it's ${formatDuration(schedule_template.self_scheduling_cancel_notice_hours * 60)} before scheduled time` : ''}` :
        'Not allowed'
    ),
  }, {
    header: 'Automated Email Follow-up',
    displayValue: ({ schedule_template }) => (
      schedule_template.self_scheduling_email_follow_up_templates?.[0] ?
        `${schedule_template.self_scheduling_email_follow_up_templates[0].delay_days} ${pluralize('weekday', schedule_template.self_scheduling_email_follow_up_templates[0].delay_days)} after initial request` :
        'Not enabled'
    ),
  }, selfSchedulingLink.schedule_template.video_conferencing_enabled && account?.video_conferencing_type === 'zoom' && {
    header: 'Zoom Host',
    displayValue: () => <FontAwesomeIcon className="check" icon={faCheck} />,
  }, account?.live_coding_type && selfSchedulingLink.stage_interview.interview_template.live_coding_enabled && {
    header: `${liveCodingLabels[account?.live_coding_type]} Link`,
    displayValue: () => <FontAwesomeIcon className="check" icon={faCheck} />,
  }, selfSchedulingLink.schedule_template.self_scheduling_request_email_template && {
    header: 'Email',
    displayValue: ({ schedule_template }) => {
      return (
        <>
          <Flash
            message={emailSubjectError?.message}
            showFlash={Boolean(emailSubjectError)}
            type="danger"
          />
          <Flash
            message={emailBodyError?.message}
            showFlash={Boolean(emailBodyError)}
            type="danger"
          />
          <Flash
            message={`An email will be sent to each of the ${applicationIDs.length} candidates. This preview is for ${arbitraryApplication.candidate.name}.`}
            showFlash={isBulk && applicationIDs.length > 1}
            type="info"
          />
          <EmailPreview
            attachments={schedule_template.self_scheduling_request_email_template!.attachments}
            bccEmails={schedule_template.self_scheduling_request_email_template!.bcc_emails?.map((email) => resolveHiringRole(email, 'email', account, currentUser, arbitraryApplication.job, arbitraryApplication, users))}
            body={renderedEmailBody?.rendered_text || <LoadingSpinner />}
            ccEmails={schedule_template.self_scheduling_request_email_template!.cc_emails?.map((email) => resolveHiringRole(email, 'email', account, currentUser, arbitraryApplication.job, arbitraryApplication, users))}
            senderEmail={resolveHiringRole(schedule_template.self_scheduling_request_email_template!.sender_email, 'email', account, currentUser, arbitraryApplication.job, arbitraryApplication, users)}
            senderName={resolveHiringRole(schedule_template.self_scheduling_request_email_template!.sender_name, 'name', account, currentUser, arbitraryApplication.job, arbitraryApplication, users)}
            subject={renderedEmailSubject?.rendered_text || <LoadingSpinner />}
            to={arbitraryApplication.candidate.email}
          />
        </>
      );
    },
  }, {
    header: 'Scheduling Calendar',
    displayValue: ({ scheduling_calendar_email }) => calendars?.calendars[scheduling_calendar_email].name,
  }, {
    header: 'Scheduling Calendar for Candidate Events',
    displayValue: ({ scheduling_calendar_email, candidate_scheduling_calendar_email }) => calendars?.calendars[candidate_scheduling_calendar_email || scheduling_calendar_email]?.name,
  }, Boolean(account?.chat_type) && {
    header: 'Create Hiring Channel',
    displayValue: ({ schedule_template }) => (
      schedule_template.create_hiring_channel ?
        <FontAwesomeIcon className="check" icon={faCheck} /> :
        <span className="empty">&mdash;</span>
    ),
  }, account?.directory_type === Directory.Google && {
    header: 'Private Interviewer Events',
    displayValue: ({ schedule_template }) => (
      schedule_template.mark_interviewer_events_as_private ?
        <FontAwesomeIcon className="check" icon={faCheck} /> :
        <span className="empty">&mdash;</span>
    ),
  }, account?.directory_type === Directory.Google && {
    header: 'Private Candidate Events',
    displayValue: ({ schedule_template }) => (
      schedule_template.mark_candidate_events_as_private ?
        <FontAwesomeIcon className="check" icon={faCheck} /> :
        <span className="empty">&mdash;</span>
    ),
  }, {
    header: 'Candidate Calendar Event',
    displayValue: ({ schedule_template }) => {
      return (
        calendarTokens && (<>
          <Flash
            message={`An invite will be sent to each of the ${applicationIDs.length} candidates. This preview is for ${arbitraryApplication.candidate.name}.`}
            showFlash={isBulk && applicationIDs.length > 1}
            type="info"
          />
          <CalendarEventTemplateSummary
            applicationId={arbitraryApplication.id}
            calendarEventTemplate={{
              title: schedule_template.candidate_calendar_event_template.title,
              description: schedule_template.candidate_calendar_event_template.description,
              location: schedule_template.video_conferencing_enabled ? undefined : schedule_template.candidate_calendar_event_template.location,
              additional_attendees: schedule_template.candidate_calendar_event_template.additional_attendees,
              additional_optional_attendees: schedule_template.candidate_calendar_event_template.additional_optional_attendees,
              type: 'candidate_calendar_event',
            }}
            candidateName={arbitraryApplication.candidate.name}
            isVideoConferencingEnabled={schedule_template.video_conferencing_enabled}
            pendingPreviewMessage={pendingPreviewMessage}
            tokens={calendarTokens}
          />
        </>)
      );
    },
  }, selfSchedulingLink.schedule_template.confirmation_email_template && {
    header: 'Confirmation Email',
    displayValue: ({ schedule_template }) => {
      return (
        confirmationEmailTokens && (
          <EmailTemplateSummary
            emailTemplate={{
              ...schedule_template.confirmation_email_template!,
              type: EmailTemplateType.ConfirmationEmail,
            }}
            jobId={arbitraryApplication.job.id}
            pendingPreviewMessage={pendingPreviewMessage}
            showFlash={false}
            tokens={confirmationEmailTokens}
          />
        )
      );
    },
  }], [
    account,
    arbitraryApplication,
    calendarTokens,
    calendars,
    confirmationEmailTokens,
    currentUser!.id,
    emailBodyError,
    emailSubjectError,
    interviewBatch,
    renderedEmailBody,
    renderedEmailSubject,
    selfSchedulingLink.schedule_template.confirmation_email_template,
    selfSchedulingLink.schedule_template.scheduling_interval_minutes,
    selfSchedulingLink.schedule_template.self_scheduling_advanced_notice_hours,
    selfSchedulingLink.schedule_template.self_scheduling_request_email_template,
    selfSchedulingLink.schedule_template.video_conferencing_enabled,
    selfSchedulingLink.stage_interview.interview_template.live_coding_enabled,
    selfSchedulingLink.interview_batch_id,
    stageInterviewsById,
    users,
  ]);

  if (completedStep < Step.Review) {
    return <Redirect to={pathForSelfScheduling(isBulk, applicationIDs, 'preferences')} />;
  }

  const schedulePreview = constructSchedulePreview(
    { ...account!, availability_message: renderedAvailabilityMessage?.rendered_text },
    selfSchedulingLink,
    stage!,
    stage!.stage_interviews?.find(({ id }) => id === selfSchedulingLink.stage_interview.id)!,
    arbitraryApplication.candidate
  );

  let nextButtonLabel = '';
  if (isBulk) {
    nextButtonLabel = selfSchedulingLink.schedule_template.self_scheduling_request_email_template ?
      `Send ${applicationIDs.length} Self-Scheduling ${pluralize('Request', applicationIDs.length)}` :
      `Create ${applicationIDs.length} Self-Scheduling ${pluralize('Link', applicationIDs.length)}`;

  } else {
    nextButtonLabel = selfSchedulingLink.schedule_template.self_scheduling_request_email_template ?
      'Send Self-Scheduling Request' :
      'Create Self-Scheduling Link';
  }

  return (
    <Breadcrumb
      data={{
        title: '3. Review',
        pathname: pathForSelfScheduling(isBulk, applicationIDs, 'review'),
      }}
    >
      <MultiStepFormStep
        backLocation={pathForSelfScheduling(isBulk, applicationIDs, 'events-and-emails')}
        className="form-step-review"
        isLastStep
        isSubmitting={createSelfSchedulingLinkMutation.isLoading || bulkCreateSelfSchedulingLinkMutation.isLoading}
        nextButtonValue={nextButtonLabel}
        onNext={handleNext}
      >
        <Helmet>
          <title>3. Review | Send {isBulk ? `${applicationIDs.length} ${pluralize('candidate', applicationIDs.length)}` : (arbitraryApplication.candidate.name || 'Unknown')} a link to schedule {stage?.name} | Gem Scheduling</title>
        </Helmet>
        <Flash
          message={createSelfSchedulingLinkMutation.error?.message}
          showFlash={createSelfSchedulingLinkMutation.isError}
          type="danger"
        />
        <Flash
          message={bulkCreateSelfSchedulingLinkMutation.error?.message}
          showFlash={bulkCreateSelfSchedulingLinkMutation.isError}
          type="danger"
        />
        <Table
          data={[selfSchedulingLink]}
          layout="horizontal"
          schema={schema}
        />
        <Flash
          message={(
            <span>
              <Link
                target="_blank"
                to={{
                  pathname: correctPath('/schedule/preview'),
                  search: encodeObjectForPreview(schedulePreview),
                }}
              >
                See a preview
              </Link>
              &nbsp;of how the link will look to the candidate.
            </span>
          )}
          showFlash={!Boolean(selfSchedulingLink.interview_batch_id)}
          type="info"
        />
        <Flash
          message={`Please contact support. Something went wrong when validating your availability message: ${availabilityMessageError?.message}`}
          showFlash={Boolean(availabilityMessageError)}
          type="danger"
        />
      </MultiStepFormStep>
    </Breadcrumb>
  );
};

export default ReviewStep;
