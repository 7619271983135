import { useFlags } from 'launchdarkly-react-client-sdk';

// The FlagSet allows us to strongly type our flags. This object is created by
// taking the keys of our flags and making them camelCase e.g. example-flag-foo
// will become exampleFlagFoo.
interface FlagSet {
  applicationAssignees: boolean;
  batchScheduling: boolean;
  candidateFacingInterviewDetails: boolean;
  distinctSchedulingWindows: boolean;
  hideTaskBadge: boolean;
  hiringMeetings: boolean;
  interviewHistoryFiltering: boolean;
  interviewerTrainingPlatform: boolean;
  manualSchedulingFlow: boolean;
  noAvailabilityDefaults: boolean;
  preferShorterBlockGap: boolean;
  progressiveScheduleGeneration: boolean;
  reauthBanner: boolean;
  saveSettingsInWorkflow: boolean;
  schedulingWithoutBlocks: boolean;
  threeBlockScheduling: boolean;
  unifiedMultiBlock: boolean;
  allowCodesignal: boolean;
  bulkSelfScheduling: boolean;
  skipForwardInSchedulingFlows: boolean;
}

export function useLDFlags () {
  return useFlags<FlagSet>();
}
