import type { Account, Application, Job, User } from '../types';
import type { UserMap } from '../hooks/queries/users';
import { startCase } from 'lodash';

export enum ResolveField {
  Email = 'email',
  Name = 'name',
}

export function resolveHiringRole (role: string, field: `${ResolveField}`, account: Account | undefined, currentUser: User | undefined, job: Job | undefined, application: Application | undefined, users: UserMap): string {
  switch (role) {
    case 'requester':
    case 'scheduler':
      let value = currentUser?.[field];
      if (account?.email_sending_domain && field === ResolveField.Email && value) {
        const parts = value.split('@');
        value = `${parts[0]}@${account.email_sending_domain}`;
      }
      return value || role;
    case 'sourcer':
    case 'recruiter':
    case 'hiring_manager':
    case 'coordinator':
      let userId = application?.[`${role}_id`];
      if (!userId) {
        userId = job?.[`${role}_id`];
      }
      if (userId && users[userId]) {
        let value = users[userId]?.[field];
        if (account?.email_sending_domain && field === ResolveField.Email && value) {
          const parts = value.split('@');
          value = `${parts[0]}@${account.email_sending_domain}`;
        }
        return value || role;
      } else if (!userId) {
        // TODO: It might be nice to return whether this case happened so the
        // styling could be different.
        return `${startCase(role)} is not set for ${job?.name || application?.candidate?.name}`;
      }
      return role;
    default:
      return role;
  }
}

export function stripSubaddress (email: string): string {
  return email.replace(/\+.+@/g, '@');
}
