import Moment from 'moment-timezone';
import { orderBy } from 'lodash';

import type { TimeSlot } from './types';

export const areAvailabilitiesEqual = (first: TimeSlot[], second: TimeSlot[]): boolean => {
  if (!first || !second) {
    return false;
  }

  if (first.length !== second.length) {
    return false;
  }

  // Order the arrays since that's not guaranteed.
  first = orderBy(first, ['start_time']);
  second = orderBy(second, ['start_time']);

  for (let i = 0; i < first.length; i++) {
    // check start times
    if (!Moment(first[i].start_time).isSame(Moment(second[i].start_time))) {
      return false;
    }
    // check end times
    if (!Moment(first[i].end_time).isSame(Moment(second[i].end_time))) {
      return false;
    }
  }

  return true;
};

export const generateAvailabilitiesForDays = (days: number, timezone: string, min?: Date, max?: Date): TimeSlot[] => {
  const start = Moment.tz(timezone).add(1, 'day').startOf('day');
  if (min) {
    const minDate = Moment.tz(min, timezone);
    start.hours(minDate.hours());
    start.minutes(minDate.minutes());
  }

  return Array.from({ length: days }).map<TimeSlot | undefined>((_, i) => {
    const day = start.clone().add(i, 'days');
    const dayOfTheWeek = day.day();

    // Skip if it's Sunday or Saturday.
    if (dayOfTheWeek === 0 || dayOfTheWeek === 6) {
      return;
    }

    const end = day.clone().endOf('day').seconds(0).milliseconds(0);
    if (max) {
      const maxDate = Moment.tz(max, timezone);
      end.hours(maxDate.hours());
      end.minutes(maxDate.minutes());
    }

    return {
      start_time: day.toDate(),
      end_time: end.toDate(),
    };
  }).filter((obj): obj is TimeSlot => Boolean(obj));
};
