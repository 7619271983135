import Config from '../config';

const LEVER_SANDBOX_TEAMS = [
  'ed1dc911-e0de-40bb-ad35-f48e15a9f0f6', // lever_sandbox_try-gem.com
  '882bd9b1-a45d-40de-887b-4e2fe0146951', // sandbox_coupa.com
];

export function getLeverHost (accountId: string | undefined) {
  // We only should consider doing this check if we're in production.
  // The configs for development and staging don't define a LEVER_SANDBOX_HOST,
  // hence the second part of the check.
  if (accountId !== undefined && Config.LEVER_SANDBOX_HOST) {
    return LEVER_SANDBOX_TEAMS.includes(accountId) ? Config.LEVER_SANDBOX_HOST : Config.LEVER_HOST;
  }
  return Config.LEVER_HOST;
}
