import { AvailabilityStatus } from '../../../../../types';

import type { Account, Application, AvailabilityPreview, Candidate, Stage } from '../../../../../types';
import type { CreateAvailabilityPayload } from '../../../../../hooks/queries/availabilities';
import type { TokensPayload } from '../../../../../hooks/queries/tokens';
import type { UserMap } from '../../../../../hooks/queries/users';

export const canSkipToReviewStep = (availability: CreateAvailabilityPayload): boolean => {
  return Boolean(availability.availability_template?.availability_request_email_template);
};

export const constructAvailabilityTokens = (application: Application, users: UserMap): TokensPayload['availability'] => ({
  application: {
    candidate: {
      email: application.candidate.email,
      name: application.candidate.name,
      pronouns: application.candidate.pronouns || undefined,
      pronunciation_url: application.candidate.pronunciation_url || undefined,
      resume_url: application.candidate.resume_url || undefined,
      phone_number: application.candidate.phone_number,
      linkedin_url: application.candidate.linkedin_url,
      website: application.candidate.website,
    },
    coordinator: application.coordinator_id && users[application.coordinator_id] ? {
      email: users[application.coordinator_id].email,
      name: users[application.coordinator_id].name,
      phone_number: users[application.coordinator_id].phone_number,
    } : undefined,
    hiring_manager: application.hiring_manager_id && users[application.hiring_manager_id] ? {
      email: users[application.hiring_manager_id].email,
      name: users[application.hiring_manager_id].name,
      phone_number: users[application.hiring_manager_id].phone_number,
    } : undefined,
    recruiter: application.recruiter_id && users[application.recruiter_id] ? {
      email: users[application.recruiter_id].email,
      name: users[application.recruiter_id].name,
      phone_number: users[application.recruiter_id].phone_number,
    } : undefined,
    sourcer: application.sourcer_id && users[application.sourcer_id] ? {
      email: users[application.sourcer_id].email,
      name: users[application.sourcer_id].name,
      phone_number: users[application.sourcer_id].phone_number,
    } : undefined,
    office: application.office ? {
      name: application.office.name,
    } : undefined,
  },
  stage: {
    name: application.current_stage!.name,
    job: {
      name: application.job.name,
      post_name: application.job.post_name,
    },
    stage_interviews: application.current_stage!.stage_interviews?.filter((stageInterview) => !stageInterview.inline && !stageInterview.deleted).map((stageInterview) => ({
      name: stageInterview.name,
      position: stageInterview.position,
      interview_template: stageInterview.interview_template ? {
        duration_minutes: stageInterview.interview_template.duration_minutes,
        candidate_facing_name: stageInterview.interview_template.candidate_facing_name,
        candidate_facing_details: stageInterview.interview_template.candidate_facing_details,
      } : undefined,
    })),
  },
});

export const constructAvailabilityPreview = (account: Account, availability: CreateAvailabilityPayload, stage: Stage, candidate: Candidate): AvailabilityPreview => ({
  account: {
    name: account.name,
    color: account.color,
    logo_url: account.logo_url,
    availability_message: account.availability_message,
    enable_pronouns: account.enable_pronouns,
    enable_pronunciation: account.enable_pronunciation,
  },
  application: {
    candidate: {
      name: candidate.name,
    },
  },
  availability_template: {
    enforce: availability.availability_template!.enforce,
    rolling_window_days: availability.availability_template!.rolling_window_days,
    business_hours: availability.availability_template!.business_hours.map((bh) => ({
      day: bh.day,
      start_time: bh.start_time || '00:00',
      end_time: bh.end_time || '24:00',
      // All business hours should have a timezone by this point.
      timezone: bh.timezone!,
    })),
    advanced_notice_hours: availability.availability_template!.advanced_notice_hours,
    minimum_duration_minutes: availability.availability_template!.minimum_duration_minutes,
    total_duration_minutes: availability.availability_template!.total_duration_minutes,
    suggested_times: availability.availability_template!.suggested_times,
  },
  stage: {
    name: stage.name,
  },
  status: AvailabilityStatus.Requested,
});
