import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHand } from '@fortawesome/free-solid-svg-icons';
import { isEmpty } from 'lodash';
import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { useState } from 'react';

import ConnectATSModal from './ConnectATSModal';
import LoadingSpinner from 'components/library/utils/LoadingSpinner';
import { useAccount } from 'hooks/queries/accounts';
import { StyledContainer, StyledHeaderContainer } from './styles';
import Flash from 'components/library/utils/Flash';
import SetCompanyBrandingModal from './SetCompanyBrandingModal';
import SetConflictKeywordsModal from './SetConflictKeywordsModal';
import SetSchedulingCalendarModal from './SetSchedulingCalendarModal';
import SetUpIntegrationsModal from './SetUpIntegrationsModal';
import WelcomeStep from './WelcomeStep';
import { correctPath } from 'libraries/gem';

enum WelcomeStepModalType {
  ATS,
  SchedulingCalendar,
  CompanyBranding,
  ConflictKeywords,
  Integrations,
}

const Welcome = () => {
  const { data: account } = useAccount();
  // TODO - Add this back and unrevert the commit which commented it out when
  // the endpoint useLatestSync accesses is fixed
  // const { data: sync, isLoading, error } = useLatestSync(account?.ats_type, {
  //   refetchInterval: 10_000,
  //   refetchOnMount: 'always',
  //   refetchOnWindowFocus: 'always',
  // });

  const [openModal, setOpenModal] = useState<WelcomeStepModalType>();

  const handleToggle = () => setOpenModal(undefined);

  if (!account) {
    return <LoadingSpinner />;
  }

  return (
    <Breadcrumb
      data={{
        title: <><FontAwesomeIcon icon={faHand} transform={{ rotate: 20 }} />Welcome</>,
        pathname: correctPath('/app/welcome'),
      }}
    >
      <StyledContainer>
        <Helmet>
          <title>Welcome | Gem</title>
        </Helmet>
        <StyledHeaderContainer>
          <h1>
            Welcome to Gem Scheduling!
          </h1>
        </StyledHeaderContainer>
        <Flash
          message="Follow the steps below to set up your account."
          showFlash={!Boolean(account?.ats_type)}
          type="info"
        />
        <WelcomeStep
          isComplete={Boolean(account?.ats_type)}
          onGetStarted={() => setOpenModal(WelcomeStepModalType.ATS)}
          stepNumber={1}
          title="Connect your ATS."
        />
        <ConnectATSModal
          isOpen={openModal === WelcomeStepModalType.ATS}
          onToggle={handleToggle}
        />
        <WelcomeStep
          canUpdateAfterComplete
          isComplete={Boolean(account?.scheduling_calendar_email)}
          onGetStarted={() => setOpenModal(WelcomeStepModalType.SchedulingCalendar)}
          stepNumber={2}
          title="Pick a default scheduling calendar."
        />
        <SetSchedulingCalendarModal
          isOpen={openModal === WelcomeStepModalType.SchedulingCalendar}
          onToggle={handleToggle}
        />
        <WelcomeStep
          canUpdateAfterComplete
          isComplete={Boolean(account?.logo && account?.color)}
          onGetStarted={() => setOpenModal(WelcomeStepModalType.CompanyBranding)}
          stepNumber={3}
          title="Add company branding."
        />
        <SetCompanyBrandingModal
          isOpen={openModal === WelcomeStepModalType.CompanyBranding}
          onToggle={handleToggle}
        />
        <WelcomeStep
          canUpdateAfterComplete
          isComplete={!isEmpty(account?.ignore_words) || !isEmpty(account?.ooo_words)}
          onGetStarted={() => setOpenModal(WelcomeStepModalType.ConflictKeywords)}
          stepNumber={4}
          title="Set up conflict keywords."
        />
        <SetConflictKeywordsModal
          isOpen={openModal === WelcomeStepModalType.ConflictKeywords}
          onToggle={handleToggle}
        />
        <WelcomeStep
          isComplete={Boolean(account?.video_conferencing_type)}
          onGetStarted={() => setOpenModal(WelcomeStepModalType.Integrations)}
          stepNumber={5}
          title="Connect video conferencing and other integrations."
        />
        <SetUpIntegrationsModal
          isOpen={openModal === WelcomeStepModalType.Integrations}
          onToggle={handleToggle}
        />
      </StyledContainer>
    </Breadcrumb>
  );
};

export default Welcome;
