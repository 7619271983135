import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from 'react-router-dom';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

import AutomaticRequesterSelectInput from '../../../../../../library/inputs/AutomaticRequesterSelectInput';
import Button from '../../../../../../library/inputs/Button';
import DayDurationInput from '../../../../../../library/inputs/DayDurationInput';
import DurationInput from '../../../../../../library/inputs/DurationInput';
import EditorInput from '../../../../../../library/inputs/EditorInput';
import EmailTemplateAdvancedSettings from '../../../../../../library/inputs/EmailTemplateAdvancedSettings';
import EmailTemplateSelectInput from '../../../../../../library/inputs/EmailTemplateSelectInput';
import EmailTemplateSummary from '../../../../../../library/data-display/EmailTemplateSummary';
import ExpandableCheckboxInput from '../../../../../../library/inputs/ExpandableCheckboxInput';
import Flash from '../../../../../../library/utils/Flash';
import { formatList } from 'libraries/formatters';
import Section from '../../../../../../library/layout/Section';
import TemplateNamePromptModal from '../../../../../../library/data-display/TemplateNamePromptModal';
import TextInput from '../../../../../../library/inputs/TextInput';
import TokenInput from '../../../../../../library/inputs/TokenInput';
import Tooltip from '../../../../../../library/utils/Tooltip';
import { ATS, directoryLabels, SchedulingWorkflow } from '../../../../../../../types';
import { DEFAULT_EMAIL_CONTENT } from '../../../../../EmailTemplates/EmailTemplateCreate/helpers';
import { SentinelTimes } from '../../../../../../../types/business-hours';
import { areAttachmentsDifferent } from '../../../../../../../libraries/editor';
import { differingValues } from '../../../../../../../libraries/differing-values';
import { downloadFileFromUrl, isFile } from '../../../../../../../libraries/form-data';
import {
  emailTemplateParams,
  useCreateEmailTemplate,
  useEmailTemplate,
  useEmailTemplatesMap,
  useUpdateEmailTemplate,
} from '../../../../../../../hooks/queries/email-templates';
import { slateValueToHtml } from '../../../../../../../libraries/editor/slate-value-to-html';
import { slateValueToText } from '../../../../../../../libraries/editor/slate-value-to-text';
import { useJob } from '../../../../../../../hooks/queries/jobs';
import { useSession } from '../../../../../../../hooks/use-session';
import { useSlateEditor } from '../../../../../../../hooks/use-slate-editor';
import { useStage, useUpdateStage } from '../../../../../../../hooks/queries/stages';
import { useTokens } from '../../../../../../../hooks/queries/tokens';
import { useUsersMap } from '../../../../../../../hooks/queries/users';

import type { AdvancedSettings } from '../../../../../../library/inputs/EmailTemplateAdvancedSettings';
import type { ChangeEvent } from 'react';
import type { CreatableEmailTemplate, EditableAttachment } from '../../../../../../../types';
import type { CreateEmailTemplatePayload, UpdateEmailTemplatePayload } from '../../../../../../../hooks/queries/email-templates';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../../../../../library/inputs/SelectInput/types';
import { correctPath } from 'libraries/gem';

const constructEmptyEmailTemplate = (jobName: string, accountName?: string): CreatableEmailTemplate => ({
  name: `[${jobName}] Self-Scheduling Email`,
  subject: `${accountName ? `${accountName} ` : ''}${DEFAULT_EMAIL_CONTENT.self_scheduling_request_email.subject}`,
  body: '',
  sender_name: 'scheduler',
  sender_email: 'scheduler',
  cc_emails: [],
  bcc_emails: [],
});

const SelfSchedulingPreferencesSection = () => {
  const queryClient = useQueryClient();

  const { id: jobId, stageId } = useParams<{ id: string; stageId: string }>();

  const { account, currentUser } = useSession();
  const { data: job } = useJob(jobId);
  const { data: stage } = useStage(jobId, stageId);
  const emailTemplates = useEmailTemplatesMap();
  const users = useUsersMap({ archived: true });

  const [error, setError] = useState<Error | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isEmailTemplateFetching, setIsEmailTemplateFetching] = useState(false);
  // self-scheduling
  const [automaticSendsEnabled, setAutomaticSendsEnabled] = useState(Boolean(stage?.schedule_template?.self_scheduling_automatic_sends_enabled_at) || false);
  const [automaticRequester, setAutomaticRequester] = useState(stage?.schedule_template?.self_scheduling_automatic_requester || currentUser?.id || '');
  const [isRescheduleEnabled, setIsRescheduleEnabled] = useState(stage?.schedule_template?.self_scheduling_reschedule_enabled || false);
  const [rescheduleNoticeHours, setRescheduleNoticeHours] = useState(stage?.schedule_template?.self_scheduling_reschedule_notice_hours || 0);
  const [isCancelEnabled, setIsCancelEnabled] = useState(stage?.schedule_template?.self_scheduling_cancel_enabled || false);
  const [cancelNoticeHours, setCancelNoticeHours] = useState(stage?.schedule_template?.self_scheduling_cancel_notice_hours || 0);
  const [isFollowUpEnabled, setIsFollowUpEnabled] = useState(Boolean(stage?.schedule_template?.self_scheduling_email_follow_up_templates?.[0]));
  const [followUpDelayDays, setFollowUpDelayDays] = useState(stage?.schedule_template?.self_scheduling_email_follow_up_templates?.[0].delay_days || 0);
  const [advancedNoticeHours, setAdvancedNoticeHours] = useState(stage?.schedule_template?.self_scheduling_advanced_notice_hours || 24);
  const [schedulingIntervalMinutes, setSchedulingIntervalMinutes] = useState(stage?.schedule_template?.scheduling_interval_minutes || 15);
  // email template
  const [emailTemplateId, setEmailTemplateId] = useState(stage?.schedule_template?.self_scheduling_request_email_template_id);
  const [name, setName] = useState(stage?.schedule_template?.self_scheduling_request_email_template?.name || '');
  const [subjectSlateEditor, subjectSlateValue, setSubjectSlateValue, setSubject] = useSlateEditor(stage?.schedule_template?.self_scheduling_request_email_template?.subject || '', true);
  const [bodySlateEditor, bodySlateValue, setBodySlateValue, setBody] = useSlateEditor(stage?.schedule_template?.self_scheduling_request_email_template?.body || '');
  const [attachments, setAttachments] = useState<EditableAttachment[]>(stage?.schedule_template?.self_scheduling_request_email_template?.attachments?.map((attachment, i) => ({ ...attachment, index: i })) || []);

  const [isTemplateNamePromptModalOpen, setIsTemplateNamePromptModalOpen] = useState(false);

  const createEmailTemplateMutation = useCreateEmailTemplate();
  const updateEmailTemplateMutation = useUpdateEmailTemplate();
  const updateStageMutation = useUpdateStage();

  const [emailTemplateAdvancedSettings, setEmailTemplateAdvancedSettings] = useState<AdvancedSettings | undefined>({
    sender_email: stage?.schedule_template?.self_scheduling_request_email_template?.sender_email || '',
    sender_name: stage?.schedule_template?.self_scheduling_request_email_template?.sender_name || '',
    cc_emails: stage?.schedule_template?.self_scheduling_request_email_template?.cc_emails,
    bcc_emails: stage?.schedule_template?.self_scheduling_request_email_template?.bcc_emails,
  });

  const { data: existingEmailTemplate } = useEmailTemplate(emailTemplateId);

  const hasEmailTemplateBeenModified = useMemo<boolean>(() => {
    return name !== (existingEmailTemplate?.name || '') ||
      slateValueToText(subjectSlateValue) !== (existingEmailTemplate?.subject || '') ||
      slateValueToHtml(bodySlateValue) !== (existingEmailTemplate?.body || '') ||
      areAttachmentsDifferent(attachments, (existingEmailTemplate?.attachments || [])) ||
      emailTemplateAdvancedSettings?.sender_name !== (existingEmailTemplate?.sender_name || '') ||
      emailTemplateAdvancedSettings?.sender_email !== (existingEmailTemplate?.sender_email || '') ||
      (emailTemplateAdvancedSettings?.cc_emails || []).length !== (existingEmailTemplate?.cc_emails || []).length ||
      (emailTemplateAdvancedSettings?.cc_emails || []).some((email, i) => email !== existingEmailTemplate?.cc_emails?.[i]) ||
      (emailTemplateAdvancedSettings?.bcc_emails || []).length !== (existingEmailTemplate?.bcc_emails || []).length ||
      (emailTemplateAdvancedSettings?.bcc_emails || []).some((email, i) => email !== existingEmailTemplate?.bcc_emails?.[i]);
  }, [
    attachments,
    bodySlateValue,
    emailTemplateAdvancedSettings,
    existingEmailTemplate,
    name,
    subjectSlateValue,
  ]);

  const { data: tokens } = useTokens({
    type: 'self_scheduling_request_email',
    self_scheduling_link: {
      application: {
        coordinator: job?.coordinator_id && users[job.coordinator_id] ? {
          email: users[job.coordinator_id].email,
          name: users[job.coordinator_id].name,
          phone_number: users[job.coordinator_id].phone_number,
        } : undefined,
        hiring_manager: job?.hiring_manager_id && users[job.hiring_manager_id] ? {
          email: users[job.hiring_manager_id].email,
          name: users[job.hiring_manager_id].name,
          phone_number: users[job.hiring_manager_id].phone_number,
        } : undefined,
        recruiter: job?.recruiter_id && users[job.recruiter_id] ? {
          email: users[job.recruiter_id].email,
          name: users[job.recruiter_id].name,
          phone_number: users[job.recruiter_id].phone_number,
        } : undefined,
        sourcer: job?.sourcer_id && users[job.sourcer_id] ? {
          email: users[job.sourcer_id].email,
          name: users[job.sourcer_id].name,
          phone_number: users[job.sourcer_id].phone_number,
        } : undefined,
        office: job?.offices && job.offices.length > 0 ? {
          name: job.offices[0].name,
        } : undefined,
      },
      stage: {
        name: stage?.name || '',
        job: {
          name: job?.name || '',
          post_name: job?.post_name,
        },
      },
      stage_interview: stage?.stage_interviews && stage.stage_interviews.length === 1 ? {
        name: stage.stage_interviews[0].name,
      } : undefined,
      interview_template: stage?.stage_interviews && stage.stage_interviews.length === 1 ? {
        duration_minutes: stage.stage_interviews[0].interview_template?.duration_minutes || stage.stage_interviews[0].ats_duration_minutes || undefined,
        candidate_facing_name: stage.stage_interviews[0].interview_template?.candidate_facing_name,
        candidate_facing_details: stage.stage_interviews[0].interview_template?.candidate_facing_details,
      } : undefined,
    },
  });

  const configurableStageInterviews = useMemo(() => (stage?.stage_interviews || []).filter((stageInterview) => {
    if (stageInterview.deleted) {
      // We don't care about deleted stage interviews.
      return false;
    }
    if (stageInterview.ats_schedulable !== null && !stageInterview.ats_schedulable) {
      // We don't care about stage interviews that are unschedulable (if schedulability is pulled from the ATS).
      return false;
    }
    // This stage interview is able to be configured for scheduling.
    return true;
  }), [account, stage]);

  const eligibleStageInterviews = useMemo(() => (configurableStageInterviews || []).filter((stageInterview) => {
    if (account?.ats_type === ATS.Lever && !stageInterview.feedback_form_id) {
      // We don't care about stage interviews that don't have a feedback form if the account uses Lever.
      return false;
    }
    if (!stageInterview.interview_template && (stageInterview.ats_duration_minutes === null || stageInterview.ats_interviewer_ids === null || stageInterview.ats_interviewer_ids.length === 0)) {
      // This stage interview doesn't have an interview template nor ATS-imported interview information, so it's
      // not ready to be scheduled.
      return false;
    }
    // This stage interview is eligible to be scheduled.
    return true;
  }), [configurableStageInterviews]);

  useEffect(() => {
    const scheduleTemplate = stage?.schedule_template;
    if (scheduleTemplate) {
      setAutomaticSendsEnabled(Boolean(scheduleTemplate.self_scheduling_automatic_sends_enabled_at) || false);
      setAutomaticRequester(scheduleTemplate.self_scheduling_automatic_requester || currentUser?.id || '');
      setIsRescheduleEnabled(scheduleTemplate.self_scheduling_reschedule_enabled);
      setRescheduleNoticeHours(scheduleTemplate.self_scheduling_reschedule_notice_hours || 0);
      setIsCancelEnabled(scheduleTemplate.self_scheduling_cancel_enabled);
      setCancelNoticeHours(scheduleTemplate.self_scheduling_cancel_notice_hours || 0);
      setIsFollowUpEnabled(Boolean(scheduleTemplate.self_scheduling_email_follow_up_templates?.[0]));
      setFollowUpDelayDays(scheduleTemplate.self_scheduling_email_follow_up_templates?.[0].delay_days || 0);
      setAdvancedNoticeHours(scheduleTemplate.self_scheduling_advanced_notice_hours);
      setSchedulingIntervalMinutes(scheduleTemplate.scheduling_interval_minutes);
      setEmailTemplateId(scheduleTemplate.self_scheduling_request_email_template_id);
      setName(scheduleTemplate.self_scheduling_request_email_template?.name || '');
      setSubject(scheduleTemplate.self_scheduling_request_email_template?.subject || '');
      setBody(scheduleTemplate.self_scheduling_request_email_template?.body || '');
      setAttachments(scheduleTemplate.self_scheduling_request_email_template?.attachments?.map((attachment, i) => ({ ...attachment, index: i })) || []);
      setEmailTemplateAdvancedSettings({
        sender_email: scheduleTemplate.self_scheduling_request_email_template?.sender_email || '',
        sender_name: scheduleTemplate.self_scheduling_request_email_template?.sender_name || '',
        cc_emails: scheduleTemplate.self_scheduling_request_email_template?.cc_emails,
        bcc_emails: scheduleTemplate.self_scheduling_request_email_template?.bcc_emails,
      });
    }
  }, [stage?.schedule_template]);

  const handleAutomaticSendEnabledChange = (e: ChangeEvent<HTMLInputElement>) => setAutomaticSendsEnabled(e.target.checked);
  const handleAutomaticRequesterChange = (option: OnChangeValue<Option<string>, false>) => setAutomaticRequester(option!.value);
  const handleRescheduleEnabledChange = (e: ChangeEvent<HTMLInputElement>) => setIsRescheduleEnabled(e.target.checked);
  const handleRescheduleNoticeHoursChange = (duration: number) => setRescheduleNoticeHours(duration / 60);
  const handleCancelEnabledChange = (e: ChangeEvent<HTMLInputElement>) => setIsCancelEnabled(e.target.checked);
  const handleCancelNoticeHoursChange = (duration: number) => setCancelNoticeHours(duration / 60);
  const handleFollowUpEnabledChange = (e: ChangeEvent<HTMLInputElement>) => setIsFollowUpEnabled(e.target.checked);
  const handleFollowUpDelayDaysChange = (duration: number) => setFollowUpDelayDays(duration);
  const handleAdvancedNoticeHoursChange = (duration: number) => setAdvancedNoticeHours(duration / 60);
  const handleSchedulingIntervalMinutesChange = (duration: number) => setSchedulingIntervalMinutes(duration);

  const handleEmailTemplateChange = async (option: OnChangeValue<Option<string>, false>) => {
    setEmailTemplateId(option?.value);

    if (!option) {
      return;
    }

    setIsEmailTemplateFetching(true);
    let newEmailTemplate;
    if (option.value === 'new') {
      newEmailTemplate = constructEmptyEmailTemplate(job!.name, account!.name);
    } else {
      try {
        newEmailTemplate = await queryClient.fetchQuery(emailTemplateParams(option.value));
      } catch (err) {
        if (err instanceof Error) {
          setError(err);
        }
        setIsEmailTemplateFetching(false);
        return;
      }
    }

    setName(newEmailTemplate.name);
    setSubject(newEmailTemplate.subject);
    setBody(newEmailTemplate.body);
    setAttachments(newEmailTemplate.attachments?.map((attachment, i) => ({ ...attachment, index: i })) || []);
    setEmailTemplateAdvancedSettings({
      sender_email: newEmailTemplate.sender_email,
      sender_name: newEmailTemplate.sender_name,
      cc_emails: newEmailTemplate.cc_emails,
      bcc_emails: newEmailTemplate.bcc_emails,
    });
    setIsEmailTemplateFetching(false);
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setAutomaticSendsEnabled(Boolean(stage?.schedule_template?.self_scheduling_automatic_sends_enabled_at) || false);
    setAutomaticRequester(stage?.schedule_template?.self_scheduling_automatic_requester || currentUser?.id || '');
    setIsRescheduleEnabled(stage?.schedule_template?.self_scheduling_reschedule_enabled || false);
    setRescheduleNoticeHours(stage?.schedule_template?.self_scheduling_reschedule_notice_hours || 0);
    setIsCancelEnabled(stage?.schedule_template?.self_scheduling_cancel_enabled || false);
    setCancelNoticeHours(stage?.schedule_template?.self_scheduling_cancel_notice_hours || 0);
    setIsFollowUpEnabled(Boolean(stage?.schedule_template?.self_scheduling_email_follow_up_templates?.[0]));
    setFollowUpDelayDays(stage?.schedule_template?.self_scheduling_email_follow_up_templates?.[0].delay_days || 0);
    setAdvancedNoticeHours(stage?.schedule_template?.self_scheduling_advanced_notice_hours || 0);
    setSchedulingIntervalMinutes(stage?.schedule_template?.scheduling_interval_minutes || 15);
    const emailTemplate = stage?.schedule_template?.self_scheduling_request_email_template;
    setEmailTemplateId(emailTemplate?.id);
    setName(emailTemplate?.name || '');
    setSubject(emailTemplate?.subject || '');
    setBody(emailTemplate?.body || '');
    setAttachments(emailTemplate?.attachments?.map((attachment, i) => ({ ...attachment, index: i })) || []);
    setEmailTemplateAdvancedSettings({
      sender_email: emailTemplate?.sender_email || '',
      sender_name: emailTemplate?.sender_name || '',
      cc_emails: emailTemplate?.cc_emails,
      bcc_emails: emailTemplate?.bcc_emails,
    });
    setIsEditing(false);
  };

  const handleSave = async () => {
    updateStageMutation.reset();
    createEmailTemplateMutation.reset();
    updateEmailTemplateMutation.reset();
    setError(null);

    let newEmailTemplateId;

    if (emailTemplateId) {
      const emailTemplatePayload: CreateEmailTemplatePayload | UpdateEmailTemplatePayload = {
        name,
        subject: slateValueToText(subjectSlateValue),
        body: slateValueToHtml(bodySlateValue),
        attachments,
        sender_name: emailTemplateAdvancedSettings?.sender_name || emailTemplateAdvancedSettings?.sender_email || 'scheduler',
        sender_email: emailTemplateAdvancedSettings?.sender_email || 'scheduler',
        cc_emails: emailTemplateAdvancedSettings?.cc_emails,
        bcc_emails: emailTemplateAdvancedSettings?.bcc_emails,
        type: 'self_scheduling_request_email',
      };

      // Check that self-scheduling request link is included in self-scheduling request email
      if (!/{{\s*SelfSchedulingRequest\.Link\s*}}/.test(emailTemplatePayload.body!)) {
        setError(new Error('The template must include the SelfSchedulingRequest.Link token.'));
        return;
      }

      // Update email template if it has been edited
      if (emailTemplateId && emailTemplateId !== 'new') {
        const existingEmailTemplate = await queryClient.fetchQuery(emailTemplateParams(emailTemplateId));
        const diff = differingValues(emailTemplatePayload as UpdateEmailTemplatePayload, existingEmailTemplate);
        if (Object.keys(diff).length > 0) {
          try {
            const payload: UpdateEmailTemplatePayload = {
              ...diff,
              cc_emails: emailTemplatePayload.cc_emails,
              bcc_emails: emailTemplatePayload.bcc_emails,
            };
            const data = await updateEmailTemplateMutation.mutateAsync({ id: emailTemplateId, payload });
            setAttachments(data.attachments?.map((attachment, i) => ({ ...attachment, index: i })) || []);
          } catch (_) {
            setIsEditing(true);
            return;
          }
        }
      }

      // Create new email template
      if (emailTemplateId === 'new') {
        try {
          const payload: CreateEmailTemplatePayload = {
            ...emailTemplatePayload as CreateEmailTemplatePayload,
            attachments: (emailTemplatePayload.attachments || []).map((file) => ({ file: file as File })),
          };
          const data = await createEmailTemplateMutation.mutateAsync({ payload });
          setAttachments(data.attachments?.map((attachment, i) => ({ ...attachment, index: i })) || []);
          newEmailTemplateId = data.id;
          setEmailTemplateId(newEmailTemplateId);
        } catch (_) {
          setIsEditing(true);
          return;
        }
      }
    }

    try {
      await updateStageMutation.mutateAsync({
        id: stageId,
        jobId,
        payload: {
          self_scheduling_automatic_sends_enabled: (newEmailTemplateId || emailTemplateId) && stage?.schedule_template?.candidate_calendar_event_template_id ? automaticSendsEnabled : false,
          self_scheduling_automatic_requester: (newEmailTemplateId || emailTemplateId) && stage?.schedule_template?.candidate_calendar_event_template_id && automaticSendsEnabled ? automaticRequester : '',
          self_scheduling_request_email_template_id: newEmailTemplateId || emailTemplateId || '',
          self_scheduling_reschedule_enabled: isRescheduleEnabled,
          self_scheduling_reschedule_notice_hours: rescheduleNoticeHours,
          self_scheduling_cancel_enabled: isCancelEnabled,
          self_scheduling_cancel_notice_hours: cancelNoticeHours,
          self_scheduling_email_follow_up_enabled: isFollowUpEnabled,
          self_scheduling_email_follow_up_delay_days: isFollowUpEnabled ? followUpDelayDays : undefined,
          self_scheduling_advanced_notice_hours: advancedNoticeHours,
          scheduling_interval_minutes: schedulingIntervalMinutes,
        },
      });
      setIsEditing(false);
    } catch (_) {
      setIsEditing(true);
    }
  };

  const createTemplateAndSaveForSaveAs = async (templateName: string) => {
    setName(templateName);

    updateStageMutation.reset();
    createEmailTemplateMutation.reset();
    updateEmailTemplateMutation.reset();

    let newEmailTemplateId;

    if (emailTemplateId) {
      // Check that self-scheduling request link is included in self-scheduling request email
      const body = slateValueToHtml(bodySlateValue);
      if (!/{{\s*SelfSchedulingRequest\.Link\s*}}/.test(body)) {
        setError(new Error('The template must include the SelfSchedulingRequest.Link token.'));
        return;
      }

      const convertedAttachments = await Promise.all((attachments || []).map(async (file) => {
        if (isFile(file)) {
          // This attachment is a newly added attachment.
          return { file };
        }
        // This attachment is an attachment on the existing template.
        const downloadedFile = await downloadFileFromUrl(file.link!, file.name);
        return { file: downloadedFile };
      }));

      const emailTemplatePayload: CreateEmailTemplatePayload = {
        name: templateName,
        subject: slateValueToText(subjectSlateValue),
        body: slateValueToHtml(bodySlateValue),
        attachments: convertedAttachments,
        sender_name: emailTemplateAdvancedSettings?.sender_name || emailTemplateAdvancedSettings?.sender_email || 'scheduler',
        sender_email: emailTemplateAdvancedSettings?.sender_email || 'scheduler',
        cc_emails: emailTemplateAdvancedSettings?.cc_emails,
        bcc_emails: emailTemplateAdvancedSettings?.bcc_emails,
        type: 'self_scheduling_request_email',
      };

      // Create new email template
      try {
        const data = await createEmailTemplateMutation.mutateAsync({ payload: emailTemplatePayload });
        setAttachments(data.attachments?.map((attachment, i) => ({ ...attachment, index: i })) || []);
        newEmailTemplateId = data.id;
        setEmailTemplateId(newEmailTemplateId);
      } catch (_) {
        setIsEditing(true);
        return;
      }
    }

    try {
      await updateStageMutation.mutateAsync({
        id: stageId,
        jobId,
        payload: {
          self_scheduling_automatic_sends_enabled: newEmailTemplateId && stage?.schedule_template?.candidate_calendar_event_template_id ? automaticSendsEnabled : false,
          self_scheduling_automatic_requester: newEmailTemplateId && stage?.schedule_template?.candidate_calendar_event_template_id && automaticSendsEnabled ? automaticRequester : '',
          self_scheduling_request_email_template_id: newEmailTemplateId || '',
          self_scheduling_reschedule_enabled: isRescheduleEnabled,
          self_scheduling_reschedule_notice_hours: rescheduleNoticeHours,
          self_scheduling_cancel_enabled: isCancelEnabled,
          self_scheduling_cancel_notice_hours: cancelNoticeHours,
          self_scheduling_email_follow_up_enabled: isFollowUpEnabled,
          self_scheduling_email_follow_up_delay_days: isFollowUpEnabled ? followUpDelayDays : undefined,
          self_scheduling_advanced_notice_hours: advancedNoticeHours,
          scheduling_interval_minutes: schedulingIntervalMinutes,
        },
      });
      setIsEditing(false);
    } catch (_) {
      setIsEditing(true);
    }
  };

  const handleSaveAs = async () => {
    if (emailTemplateId && emailTemplateId !== 'new') {
      // This template is based on another one, so we want to compare the names
      // to see if they're different. If they aren't, open a modal recommending
      // them to update it.
      const existingEmailTemplate = await queryClient.fetchQuery(emailTemplateParams(emailTemplateId));
      if (name === existingEmailTemplate.name) {
        setIsTemplateNamePromptModalOpen(true);
        return;
      }
    }

    await createTemplateAndSaveForSaveAs(name);
  };

  const isLoading = updateStageMutation.isLoading ||
    createEmailTemplateMutation.isLoading ||
    updateEmailTemplateMutation.isLoading;
  const isUpdated = (stage?.schedule_template?.self_scheduling_request_email_template_id || '') !== (emailTemplateId || '');
  const linkedStages = (emailTemplateId && emailTemplates[emailTemplateId]?.linked_resources) ?? null;
  const selectedTemplateIsEditableInline = !emailTemplateId || (!isUpdated && linkedStages === 1) || (isUpdated && !linkedStages);

  return (
    <Section
      additionalHeaderActions={isEditing && emailTemplateId && emailTemplateId !== 'new' && (
        <Button
          color="gem-outline"
          iconRight={<FontAwesomeIcon icon={faSave} />}
          isDisabled={isLoading}
          onClick={handleSaveAs}
          size="small"
          value="Save with new email template"
        />
      )}
      className="job-stage-self-scheduling-preferences"
      isEditable
      isEditing={isEditing}
      isSaveButtonDisabled={isEmailTemplateFetching || (!selectedTemplateIsEditableInline && hasEmailTemplateBeenModified)}
      isSaving={updateStageMutation.isLoading}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      saveButtonTooltip={!selectedTemplateIsEditableInline && hasEmailTemplateBeenModified ? (
        <Tooltip
          id="job-stage-self-scheduling-preferences-disabled-save"
          position="top"
          value="You cannot edit this template from this page because it is linked to other stages."
        />
      ) : undefined}
      title="Self-scheduling preferences"
    >
      <Flash
        message="You can skip this section if you do not plan to use self-scheduling links for this stage."
        showFlash={stage?.schedule_template?.default_workflow !== SchedulingWorkflow.SelfSchedule}
        type="info"
      />
      <Flash
        isDismissible
        message="Successfully updated!"
        showFlash={updateStageMutation.isSuccess}
        type="success"
      />
      <Flash
        message={updateStageMutation.error?.message || createEmailTemplateMutation.error?.message || updateEmailTemplateMutation.error?.message}
        showFlash={updateStageMutation.isError || createEmailTemplateMutation.isError || updateEmailTemplateMutation.isError}
        type="danger"
      />
      <Flash
        message={error?.message}
        showFlash={Boolean(error)}
        type="danger"
      />
      <Flash
        message={<>You currently don&apos;t have timezones set for your scheduling windows. We <b>will not</b> use any windows that don&apos;t have a timezone when sending the request automatically. Set timezones above.</>}
        showFlash={Boolean(stage?.schedule_template?.self_scheduling_automatic_sends_enabled_at && stage.schedule_template.business_hours?.some((bh) => !bh.timezone && (bh.start_time !== SentinelTimes.StartOfDay || bh.end_time !== SentinelTimes.EndOfDay)))}
        type="warning"
      />
      <ExpandableCheckboxInput
        isChecked={Boolean(emailTemplateId && stage?.schedule_template?.candidate_calendar_event_template_id && automaticSendsEnabled)}
        isDisabled={!isEditing || updateStageMutation.isLoading || !emailTemplateId || !stage?.schedule_template?.candidate_calendar_event_template_id || Boolean(eligibleStageInterviews.length !== 1 && !stage?.schedule_template?.self_scheduling_automatic_sends_enabled_at)}
        label="Automatically send a self-scheduling request when the candidate moves into this stage."
        onChange={handleAutomaticSendEnabledChange}
        tooltip={!emailTemplateId || !stage?.schedule_template?.candidate_calendar_event_template_id || Boolean(eligibleStageInterviews.length !== 1 && !stage?.schedule_template?.self_scheduling_automatic_sends_enabled_at) ? (
          <Tooltip
            id="automatically-send-disabled-tooltip-interview"
            position="top"
            value={`To enable this feature, you must ${formatList([
              !emailTemplateId && 'set up an email template below',
              !stage?.schedule_template?.candidate_calendar_event_template_id && 'set up a candidate calendar event template above',
              configurableStageInterviews.length === 0 && 'add an interview',
              eligibleStageInterviews.length === 0 && configurableStageInterviews.length === 1 && `configure a default interviewer${account?.ats_type === ATS.Lever ? ' and feedback form' : ''} for the ${configurableStageInterviews[0].name} interview`,
              eligibleStageInterviews.length === 0 && configurableStageInterviews.length > 1 && `configure a default interviewer${account?.ats_type === ATS.Lever ? ' and feedback form' : ''} on the interview to be self-scheduled`,
              eligibleStageInterviews.length > 1 && 'have only one interview configured on the stage',
            ].filter((obj) => Boolean(obj)) as string[])}.`}
          />
        ) : undefined}
      >
        <AutomaticRequesterSelectInput
          getUserDisabledTooltipMessage={(user) => !user.connected_directory && account ? `${user.name || user.email} hasn't connected their ${directoryLabels[account.directory_type]} account, so they can't be the requester.` : undefined}
          getUserIsDisabled={(user) => !user.connected_directory}
          isDisabled={!isEditing || updateStageMutation.isLoading}
          onChange={handleAutomaticRequesterChange}
          value={automaticRequester}
        />
      </ExpandableCheckboxInput>
      <ExpandableCheckboxInput
        isChecked={isRescheduleEnabled}
        isDisabled={!isEditing || isLoading}
        label="Allow candidate to reschedule."
        onChange={handleRescheduleEnabledChange}
      >
        <DurationInput
          additionalText="before scheduled time"
          className="reschedule-notice-hours-input"
          id="reschedule-notice-hours-input"
          isDisabled={!isEditing || isLoading}
          isHoursOnly
          isRequired
          label="Minimum Reschedule Notice"
          maxMinutes={178 * 60}
          onChange={handleRescheduleNoticeHoursChange}
          value={rescheduleNoticeHours * 60}
        />
      </ExpandableCheckboxInput>
      <ExpandableCheckboxInput
        isChecked={isCancelEnabled}
        isDisabled={!isEditing || isLoading}
        label="Allow candidate to cancel."
        onChange={handleCancelEnabledChange}
      >
        <DurationInput
          additionalText="before scheduled time"
          className="cancel-notice-hours-input"
          id="cancel-notice-hours-input"
          isDisabled={!isEditing || isLoading}
          isHoursOnly
          isRequired
          label="Minimum Cancel Notice"
          maxMinutes={178 * 60}
          onChange={handleCancelNoticeHoursChange}
          value={cancelNoticeHours * 60}
        />
      </ExpandableCheckboxInput>
      <ExpandableCheckboxInput
        isChecked={isFollowUpEnabled}
        isDisabled={!isEditing || isLoading}
        label="Automatically follow up with the candidate if they don't schedule an interview."
        onChange={handleFollowUpEnabledChange}
      >
        <DayDurationInput
          additionalText="after initial request"
          className="follow-up-days-input"
          dayLabel="weekday"
          id="follow-up-days-input"
          isDisabled={!isEditing || isLoading}
          isRequired
          label="Days Before Sending Follow-up"
          min={1}
          onChange={handleFollowUpDelayDaysChange}
          value={followUpDelayDays}
        />
      </ExpandableCheckboxInput>
      <DurationInput
        className="advanced-notice-hours-input"
        helperText="The candidate must submit times that are at least this far in the future."
        id="advanced-notice-hours-input"
        isDisabled={!isEditing || isLoading}
        isHoursOnly
        isRequired
        label="Advanced Notice"
        maxMinutes={178 * 60}
        onChange={handleAdvancedNoticeHoursChange}
        value={advancedNoticeHours * 60}
      />
      <DurationInput
        className="scheduling-interval-minutes-input"
        helperText="The interval that we'll use to generate possible slots for the candidate."
        id="scheduling-interval-minutes-input"
        isDisabled={!isEditing || isLoading}
        isRequired
        label="Scheduling Options Interval"
        maxMinutes={55}
        minMinutes={5}
        onChange={handleSchedulingIntervalMinutesChange}
        value={schedulingIntervalMinutes}
      />
      <div className="job-stage-self-scheduling-request-email">
        <div className="form-container">
          <EmailTemplateSelectInput
            isDisabled={!isEditing || isLoading}
            isInlineCreatable
            onChange={handleEmailTemplateChange}
            type="self_scheduling_request_email"
            value={emailTemplateId}
          />
        </div>
        {emailTemplateId && (
          ((isEditing || isLoading) ? (
            <div className="email-template-inline-form">
              <Flash
                message={<span>This template is linked to other stages. You can either create a new template here with any changes you make, or go to the <Link to={correctPath(`/app/email-templates/${emailTemplateId}`)}>email template page</Link> to edit the original template.</span>}
                showFlash={!selectedTemplateIsEditableInline}
                type="info"
              />
              <div className="form-container">
                <TextInput
                  isRequired
                  label="Template Name"
                  onChange={handleNameChange}
                  value={name}
                />
              </div>
              <div className="form-container">
                <TokenInput
                  editor={subjectSlateEditor}
                  isRequired
                  label="Subject"
                  pendingPreviewMessage="You can preview this token when you are creating a self-scheduling request for a candidate."
                  setValue={setSubjectSlateValue}
                  tokens={tokens}
                  type="self_scheduling_request_email"
                  value={subjectSlateValue}
                />
              </div>
              <EditorInput
                allowImages
                attachments={attachments}
                editor={bodySlateEditor}
                exampleHtmlContent={DEFAULT_EMAIL_CONTENT.self_scheduling_request_email.body}
                label="Body"
                pendingPreviewMessage="You can preview this token when you are creating a self-scheduling request for a candidate."
                setAttachments={setAttachments}
                setValue={setBodySlateValue}
                tokens={tokens}
                type="self_scheduling_request_email"
                value={bodySlateValue}
              />
              <EmailTemplateAdvancedSettings
                emailTemplate={emailTemplateAdvancedSettings}
                setEmailTemplate={setEmailTemplateAdvancedSettings}
              />
              <TemplateNamePromptModal
                isOpen={isTemplateNamePromptModalOpen}
                onSubmit={createTemplateAndSaveForSaveAs}
                onToggle={() => setIsTemplateNamePromptModalOpen((prev) => !prev)}
                templateName={name}
              />
            </div>
          ) :
            <EmailTemplateSummary
              id={emailTemplateId}
              jobId={job?.id}
              pendingPreviewMessage="You can preview this token when you are creating a self-scheduling request for a candidate."
              tokens={tokens}
            />
          )
        )}
      </div>
    </Section>
  );
};

export default SelfSchedulingPreferencesSection;
