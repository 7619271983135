import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet-async';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { find } from 'lodash';
import { useCallback, useMemo } from 'react';

import CandidateListTable from './CandidateListTable';
import { JobStatus } from '../../../../types';
import Flash from '../../../library/utils/Flash';
import JobSelectInput from '../../../library/inputs/JobSelectInput';
import StageSelectInput from '../../../library/inputs/StageSelectInput';
import LoadingSpinner from '../../../library/utils/LoadingSpinner';
import Section from '../../../library/layout/Section';
import SelectInput from '../../../library/inputs/SelectInput';
import TextInput from '../../../library/inputs/TextInput';
import UserSelectInput from '../../../library/inputs/UserSelectInput';
import useSyncStateWithQuery from '../../../../hooks/use-sync-state-with-query';
import { useApplications } from '../../../../hooks/queries/applications';
import { useLDFlags } from '../../../../hooks/use-ld-flags';

import type { ChangeEvent } from 'react';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../../library/inputs/SelectInput/types';

const statusOptions: Option<string>[] = [
  { value: 'ready_to_schedule', label: 'Ready to Schedule' },
  { value: 'scheduled', label: 'Scheduled' },
  { value: 'cancelled', label: 'Cancelled' },
  { value: 'scheduling_link_sent', label: 'Scheduling Link Sent' },
  { value: 'availability_requested', label: 'Availability Requested' },
  { value: 'ready_to_request_availability', label: 'Ready to Request Availability' },
  { value: 'unschedulable', label: 'Unschedulable' },
  { value: 'inactive', label: 'Inactive' },
];
const defaultStatuses = statusOptions.map(({ value }) => value).slice(0, 6);
const DEFAULT_CANDIDATE_LIST_PAGE_SIZE = 10;
// TODO @benhiller: Increase to 50 once timeouts are fixed
const EXPANDED_CANDIDATE_LIST_PAGE_SIZE = 35;

const CandidateList = () => {
  const { applicationAssignees, bulkSelfScheduling } = useLDFlags();

  const pageSize = bulkSelfScheduling ? EXPANDED_CANDIDATE_LIST_PAGE_SIZE : DEFAULT_CANDIDATE_LIST_PAGE_SIZE;

  const [search, querySearch, setSearch] = useSyncStateWithQuery<string>('q', '', { delay: 200 });
  const [showArchived, , setShowArchived] = useSyncStateWithQuery<string>('show_archived', 'false');
  const [status, queryStatus, setStatus] = useSyncStateWithQuery<string[]>('status', defaultStatuses, { stripDefault: false, array: true });
  const [job, queryJob, setJob] = useSyncStateWithQuery<string>('job', '');
  const [stage, queryStage, setStage] = useSyncStateWithQuery<string>('stage', '');
  const [including, queryIncluding, setIncluding] = useSyncStateWithQuery<string>('including', '');
  const [assignee, queryAssignee, setAssignee] = useSyncStateWithQuery<string>('assignee', '');
  const [pageNumber, queryPageNumber, setPageNumber] = useSyncStateWithQuery<string>('page', '1');

  const {
    data: applications,
    error,
    isFetching,
  } = useApplications({
    archived: showArchived === 'true',
    candidate_name_or_email: querySearch, // This has to be querySearch so it doesn't make a lot of requests when you type fast.
    job: queryJob,
    stage: queryStage,
    limit: pageSize,
    offset: pageSize * (parseInt(queryPageNumber, 10) - 1),
    scheduling_status: queryStatus,
    assigned_user: queryAssignee !== '' ? queryAssignee : undefined,
    including_user: queryIncluding !== '' ? queryIncluding : undefined,
  });

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    if (parseInt(pageNumber, 10) > 1) {
      setPageNumber('1', { method: 'replace' });
    }
  }, [pageNumber]);

  const handleShowArchivedChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setShowArchived(JSON.stringify(e.target.checked));
  }, []);

  const handleStatusChange = useCallback((option: OnChangeValue<Option<string>, true>) => {
    setStatus(option && option.length > 0 ? option.map((status) => status.value) : []);
    if (parseInt(pageNumber, 10) > 1) {
      setPageNumber('1', { method: 'replace' });
    }
  }, [pageNumber]);

  const handleJobChange = useCallback((option: OnChangeValue<Option<string>, false>) => {
    setJob(option ? option.value : '');
    // Clear the stage as well, since you can only filter by stage when already
    // filtering by job.
    setStage('');
    if (parseInt(pageNumber, 10) > 1) {
      setPageNumber('1', { method: 'replace' });
    }
  }, [pageNumber]);

  const handleIncludingChange = useCallback((option: OnChangeValue<Option<string>, false>) => {
    setIncluding(option?.value || '');
  }, []);

  const handleAssigneeChange = useCallback((option: OnChangeValue<Option<string>, false>) => {
    setAssignee(option?.value || '');
  }, []);

  const handleStageChange = useCallback((option: OnChangeValue<Option<string>, false>) => {
    setStage(option ? option.value : '');
    if (parseInt(pageNumber, 10) > 1) {
      setPageNumber('1', { method: 'replace' });
    }
  }, [pageNumber]);

  const handlePageNumberChange = useCallback((value: number) => {
    setPageNumber(value.toString());
  }, []);

  const selectedStatuses = useMemo<Option<string>[]>(() => {
    if (!status) {
      return [];
    }
    return status
    .map((status) => find(statusOptions, ['value', status]))
    .filter((s): s is Option<string> => Boolean(s));
  }, [status]);

  const stageSelectInput = (
    <StageSelectInput
      className="select-input-candidate-stage"
      label="Stage"
      onChange={handleStageChange}
      stageQuery={{
        job: queryJob,
        limit: 100,
        job_status: [JobStatus.Open, JobStatus.Draft, JobStatus.Closed],
      }}
      value={stage}
    />
  );

  return (
    <div className="candidate-list-container">
      <Helmet>
        <title>Candidates | Gem Scheduling</title>
      </Helmet>
      <Section title="Candidates">
        <div className="filters-container">
          <TextInput
            className="text-input-candidate-filter"
            isAutofocus
            label="Search"
            leftIcon={isFetching ? <LoadingSpinner /> : <FontAwesomeIcon icon={faSearch} />}
            onChange={handleSearchChange}
            placeholder="Candidate name or email"
            value={search}
          />
          {!applicationAssignees && (
            <>
              <JobSelectInput
                className="select-input-candidate-job"
                label="Job"
                onChange={handleJobChange}
                value={job}
              />
              {job && stageSelectInput}
              <UserSelectInput
                annotateCurrentUser
                className="select-input-candidate-including"
                currentUserFirst
                isClearable
                label="Including"
                name="select-input-candidate-including"
                onChange={handleIncludingChange}
                value={including}
              />
            </>
          )}
        </div>
        {applicationAssignees && (
          <div className="filters-container">
            <JobSelectInput
              className="select-input-candidate-job"
              label="Job"
              onChange={handleJobChange}
              value={job}
            />
            {job && stageSelectInput}
            <UserSelectInput
              annotateCurrentUser
              className="select-input-candidate-including"
              currentUserFirst
              isClearable
              label="Including"
              name="select-input-candidate-including"
              onChange={handleIncludingChange}
              value={including}
            />
            <UserSelectInput
              annotateCurrentUser
              className="select-input-candidate-assignee"
              currentUserFirst
              isClearable
              label="Assignee"
              name="select-input-candidate-assignee"
              onChange={handleAssigneeChange}
              value={assignee}
            />
          </div>
        )}
        <div className="filters-container">
          <SelectInput
            className="select-input-candidate-status"
            isClearable
            isMulti
            label="Status"
            onChange={handleStatusChange}
            options={statusOptions}
            value={selectedStatuses}
          />
        </div>
        <Flash
          message={error?.message}
          showFlash={Boolean(error)}
          type="danger"
        />
        {applications &&
          <CandidateListTable
            applications={applications.applications}
            onPageNumberChange={handlePageNumberChange}
            onShowArchivedChange={handleShowArchivedChange}
            pageNumber={parseInt(pageNumber, 10)}
            pageSize={pageSize}
            showArchived={showArchived === 'true'}
            totalCount={applications.total}
          />
        }
      </Section>
    </div>
  );
};

export default CandidateList;
